import React from "react"
import {
    Layout, 
    MainContainer,    
    UserInfo
} from '../app/components'

export default (props) => {    
   
    return(       
        <Layout location={props.location}>  
            <MainContainer padding={"0"}>
                <UserInfo />
            </MainContainer> 
        </Layout>                                      
    )
}
